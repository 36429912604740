.pulsate {
    -webkit-animation: pulsate 1.4s linear;
    -webkit-animation: pulsate 1.4s linear;
    -moz-animation: pulsate 1.4s linear;
    animation: pulsate 1.4s linear;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    opacity: 0.5;
  }
  @keyframes pulsate {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 1.0;
    }
    100% {
      opacity: 0.3;
    }
  }
  .plz-wait {
    color: #e8e8e8;
    font-size: 0.65em;
    margin-top: 20px;
  }
  #loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  #loader-bitcoin {
    z-index: 1001;
    position: fixed;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 600px;
    text-align: center;
    color: #e8e8e8;
    font-size: 1.8em;
  }
  .svg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .path {
    stroke-dasharray: 2110;
    stroke-dashoffset: 2110;
    stroke-width: 2;
    stroke-linecap: round;
    animation: dash 6s linear infinite;
    fill-opacity: 0;
    stroke: #be6500;
    width: 122px;
    height: 122px;
  }
  @keyframes dash {
    0% {
      stroke-dashoffset: 2110;
      opacity: 0;
      stroke: #be6500;
    }
    15% {
      opacity: 1;
      stroke: #be6500;
    }
    70% {
      opacity: 1;
      stroke: #be6500;
    }
    100% {
      stroke-dashoffset: 0;
      opacity: 0;
      stroke: #ffc451;
    }
  }
  #loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 80px;
    height: 80px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #0d579b;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    z-index: 1001;
  }
  #loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #329239;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }
  #loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #f7931a;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  #loader-wrapper .loader-section {
    position: fixed;
    top: 0;
    width: 51%;
    height: 100%;
    background: #121212;
    z-index: 1000;
  }
  #loader-wrapper .loader-section.section-left {
    left: 0;
  }
  #loader-wrapper .loader-section.section-right {
    right: 0;
  }

  /* Left and Right Entrance */
  .loaded #loader-wrapper .loader-section.section-left {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
  }
  .loaded #loader-wrapper .loader-section.section-right {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
  }

  .loaded #loader, .loaded .loader-text, .loaded #loader-bitcoin {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .loaded #loader-wrapper {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: all 0.3s 1s ease-out;
    transition: all 0.3s 1s ease-out;
  }
  .bitcoin-price-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
 