.btcpay-form {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btcpay-form--inline {
    flex-direction: row;
}

.btcpay-form--block {
    flex-direction: column;
}

.btcpay-form--inline .submit {
    margin-left: 15px;
}

.btcpay-form--block select {
    margin-bottom: 10px;
}

.btcpay-form .btcpay-custom-container {
    text-align: center;
}

.btcpay-custom {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btcpay-form .plus-minus {
    cursor: pointer;
    font-size: 25px;
    line-height: 25px;
    background: #DFE0E1;
    height: 30px;
    width: 45px;
    border: none;
    border-radius: 60px;
    margin: auto 5px;
    display: inline-flex;
    justify-content: center;
}

.btcpay-form select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    color: currentColor;
    background: transparent;
    border: 1px solid transparent;
    display: block;
    padding: 1px;
    margin-left: auto;
    margin-right: auto;
    font-size: 11px;
    cursor: pointer;
}

.btcpay-form select:hover {
    border-color: #ccc;
}

.btcpay-form option {
    color: #000;
    background: rgba(0, 0, 0, .1);
}

.btcpay-input-price {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
    border: none;
    box-shadow: none;
    text-align: center;
    font-size: 25px;
    margin: auto;
    border-radius: 5px;
    line-height: 35px;
    background: #fff;
    color: #000;
}

.btcpay-input-price::selection {
    background-color: lightblue; /* Replace with the desired light orange color */
}

.btcpay-input-price::-webkit-outer-spin-button,
.btcpay-input-price::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=range].btcpay-input-range {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    background: transparent;
}

input[type=range].btcpay-input-range:focus {
    outline: 0;
}

input[type=range].btcpay-input-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 3.1px;
    cursor: pointer;
    box-shadow: 0 0 1.7px #020, 0 0 0 #003c00;
    background: #f3f3f3;
    border-radius: 1px;
    border: 0;
}

input[type=range].btcpay-input-range::-webkit-slider-thumb {
    box-shadow: none;
    border: 2.5px solid #cedc21;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: #0f3723;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9.45px
}

input[type=range].btcpay-input-range:focus::-webkit-slider-runnable-track {
    background: #fff;
}

input[type=range].btcpay-input-range::-moz-range-track {
    width: 100%;
    height: 3.1px;
    cursor: pointer;
    box-shadow: 0 0 1.7px #020, 0 0 0 #003c00;
    background: #f3f3f3;
    border-radius: 1px;
    border: 0;
}

input[type=range].btcpay-input-range::-moz-range-thumb {
    box-shadow: none;
    border: 2.5px solid #cedc21;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: #0f3723;
    cursor: pointer;
}

input[type=range].btcpay-input-range::-ms-track {
    width: 100%;
    height: 3.1px;
    cursor: pointer;
    background: 0 0;
    border-color: transparent;
    color: transparent;
}

input[type=range].btcpay-input-range::-ms-fill-lower {
    background: #e6e6e6;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 0 1.7px #020, 0 0 0 #003c00;
}

input[type=range].btcpay-input-range::-ms-fill-upper {
    background: #f3f3f3;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 0 1.7px #020, 0 0 0 #003c00;
}

input[type=range].btcpay-input-range::-ms-thumb {
    box-shadow: none;
    border: 2.5px solid #cedc21;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: #0f3723;
    cursor: pointer;
    height: 3.1px;
}

input[type=range].btcpay-input-range:focus::-ms-fill-lower {
    background: #f3f3f3;
}

input[type=range].btcpay-input-range:focus::-ms-fill-upper {
    background: #fff;
}

.submit {
    min-width: 168px;
    min-height: 46px;
    border-radius: 4px;
    border-style: none;
    background-color: #0f3b21;
    cursor: pointer;
    margin-top: 10px;
    border: 2px solid transparent;
  }
  
  .submit:hover {
    border: 2px solid #cedc21;
  }

  .submit:active {
    border: 1px solid #cedc21;
  }
  
  .submit span {
    color: #fff;
    font-size: 13px;
  }
  
  .submit img {
    height: 46px;
    display: inline-block;
    padding: 4% 0 4% 7px;
    vertical-align: middle;
  }
