body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

.navbar-container {
  position: -webkit-sticky;
  position: sticky;
  top: calc(58px + 0px);
  z-index: 991;
}

/* ApexCharts styles */
.apexcharts-zoom-icon.apexcharts-selected svg {
  fill: #f7931a !important;
}

.apexcharts-pan-icon.apexcharts-selected svg {
  stroke: #f7931a !important;
}

/* Start New Block Animation */

@keyframes orange-border {
  /* Tracing phase */
  0%, 5% {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  25% {
    border-top-color: #ff8c00;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #ff8c00;
  }
  50% {
    border-top-color: #ff8c00;
    border-right-color: #ff8c00;
    border-bottom-color: #ff8c00;
    border-left-color: #ff8c00;
  }
  /* Glow and pulse phase (reduced effect) */
  60% {
    border-color: #ffd700;
    box-shadow: 0 0 5px #ffd700;
  }
  70% {
    border-color: #ff8c00;
    box-shadow: 0 0 3px #ff8c00;
  }
  80% {
    border-color: #ffa500;
    box-shadow: 0 0 8px #ffa500;
  }
  /* Fade out phase */
  90%, 100% {
    border-color: transparent;
    box-shadow: none;
  }
}

.orange-border {
  position: relative;
}

.orange-border:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 12px !important;
  left: 0;
  border: 2px solid transparent;
  animation: orange-border 8s 2; /* Changed to 2 iterations */
  border-radius: 5px;
}

@keyframes dark-gray-border {
  /* Tracing phase */
  0%, 5% {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  25% {
    border-top-color: #a0a0a0;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #a0a0a0;
  }
  50% {
    border-top-color: #a0a0a0;
    border-right-color: #a0a0a0;
    border-bottom-color: #a0a0a0;
    border-left-color: #a0a0a0;
  }
  /* Glow and pulse phase (reduced effect) */
  60% {
    border-color: #c0c0c0;
    box-shadow: 0 0 5px #c0c0c0;
  }
  70% {
    border-color: #a0a0a0;
    box-shadow: 0 0 3px #a0a0a0;
  }
  80% {
    border-color: #808080;
    box-shadow: 0 0 8px #808080;
  }
  /* Fade out phase */
  90%, 100% {
    border-color: transparent;
    box-shadow: none;
  }
}

.gray-border {
  position: relative;
}

.gray-border:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 12px !important;
  left: 0;
  border: 2px solid transparent;
  animation: dark-gray-border 8s 2; /* Changed to 2 iterations */
  border-radius: 5px;
}

.gray-border-custom:before {
  bottom: 0 !important;
  border-radius: 3px;
}

/* End New Block Animation */

.unselectable {
  /* Chrome, Safari, and Opera browsers */
  -webkit-user-select: none;
  /* Firefox browser */
  -moz-user-select: none;
  /* Internet Explorer and Edge browsers */
  -ms-user-select: none;
  /* Standard property for other browsers */
  user-select: none;
  /* iOS Safari specific */
  -webkit-touch-callout: none;
}